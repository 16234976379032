import Global from "./global.js";
import Modal from "./modal.js";

const Home = {
  init: function () {
    this.cacheSelectors(); 
  },
  cacheSelectors: function () {
    this.tabs = document.querySelectorAll('.tablist button') ? document.querySelectorAll('.tablist button') : [];
    this.painels = document.querySelectorAll('.block-painel .painel') ? document.querySelectorAll('.block-painel .painel') : [];

    if (this.tabs.length > 0) {
      this.tabs.forEach(tab => {
        tab.addEventListener('click', () => {
          this.Events.handleTabClick(tab);
        });
      });
    }

  },
  Events: {
    handleTabClick: (tab) => {
      const App = Home;
      if(tab.classList.contains('active')) { return; }
      const target = tab.getAttribute('data-target');
      
      App.painels.forEach(painel => {
        if(painel.classList.contains('flex-block')) {
          painel.classList.remove('show');
          painel.classList.remove('flex-block');
          painel.classList.add('flex-none');
        }
      });
      
      App.tabs.forEach(tab => { 
        if(tab.classList.contains('active')) {
          tab.classList.remove('active');
        }
      });

      document.getElementById(target).classList.add('flex-block');
      document.getElementById(target).classList.remove('flex-none');

      setTimeout(() => {
        document.getElementById(target).classList.add('show');
      }, 100);

      tab.classList.add('active');  
    }
  }
};


document.addEventListener("DOMContentLoaded", function () {
  Home.init();
  Global.init();
  Modal.init();
});


